@font-face {
  font-family: otter;
  src: url("./fonts/04B_30__.TTF");
}
@font-face {
  font-family: otter-sec;
  src: url("./fonts/ARCADE.TTF");
}
:root {
  --main: #34374c;
  --sec: #2c2e3e;
  --red: #ee2b47;
  --white: #f6f6f6;
}
* {
  /* font-family: otter; */
  margin: 0%;
}
body {
  background-color: var(--white) !important;
}
.MintWithButtons{
  margin: 2px;

}
.head05 {
  font-family: otter;
  font-size: 120%;
  color:white;
  top: 60%;
position: absolute;
width: 100%;
  text-align: center;
}
.head06 {
  font-family: otter;
  font-size: 140%;
  color:white;
  top: 60%;
border-bottom: 7px solid #e96075;
border-radius: 15px;
padding: 5px;
background-color: #ee2b47;
  text-align: center;
}
.head06:active{
  background-color: #2c2e3e;
  scale: 0.9;
  transition: 0.1s ;
  box-shadow: none;
  background: transparent;
}
.mintMain{
  padding-top: 10px;
  height: 100vh;
  width: 100%;
  background-color: #2c2e3e;
background: url('./img/mintbg.png') ;
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
}
.iconsTop {
  margin-left: 20px;
}
.iconsTop:hover {
  cursor: pointer;
}


.mintPageTitles{
text-align: left;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:var(--white);
}
.mintCard{
  /* height: 100%;
  width: 100%;
  background: url('./img/mintcard.png') ; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  /* position: relative; */
  
}
.MintH {
  font-family: otter;
  font-size: 350%;
  color: var(--red);
}

#errorMsg{
  padding-top: 5px;
  color: var(--red);
  font-family: otter-sec;
  font-size: 120%;
text-align: center;
}
.address{
  color: var(--main);
  font-family:otter;
  display: none;
  font-size: 80%;
}
.preloader {
  animation: preloader 1s ease infinite;
}

@keyframes preloader {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  
  100% {
    transform: scale(1, 1);
  }
}
button.cutomButton{
  background-color: var(--red);
  /* width: 120px; */
  height: 50px;
  font-size: 12px;
  padding: 5px;
}


.glow {
  font-size: 12px;
  font-family: otter;
  font-weight: 100;
  text-align: center;
  /* border-bottom:1px solid  #00e6d3; */
  text-shadow: 0px 5px 10px  rgb(0, 219, 183), 0 0 20px rgb(255, 255, 255), 0 0px 30px #00e6d3;

}

.mitem{
  background-color: #2c2e3e;
}
html body div#fade-menu.MuiPopover-root.MuiMenu-root.MuiModal-root.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  background-color: #2c2e3ed7;

}
html body div#root div.MuiBox-root.css-0 div.mintMain.MuiBox-root.css-1jf9xzp div.css-3fqsov-MuiStack-root div.css-u6xmtm-MuiStack-root div.MuiBox-root.css-0 div.mintCard.MuiBox-root.css-19ddxoh div.MuiBox-root.css-0 div.css-1bufj1n-MuiStack-root div.MuiBox-root.css-0 div.css-1rlmiz5-MuiStack-root div.MuiBox-root.css-0 div.css-16o20ks-MuiStack-root div.css-1ns416o-MuiStack-root div.MintWithButtons.MuiBox-root.css-0 button.crossmintButton-0-2-1.crossmintButton-d38-0-2-42.cutomButton{

}

